import React from 'react'
import { useSelector } from 'react-redux';
import Login from './Login';
import Videos from './Videos';
import { useLocation } from 'react-router-dom'
import  Cookies  from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/loginSlice';
export default function Main() {
    const isSchool =  useSelector((state) => state.loginSlice.isLoggedIn);
    const location = useLocation();
    // Cookies.set("current_role",location.pathname)
    const dispatch = useDispatch()
    if(location.pathname !== Cookies.get("current_role")){
        Cookies.remove("token")
        Cookies.remove("role")
        Cookies.remove("current_role")
        Cookies.remove("current_qr")
        dispatch(logout())
    }
  return (

        isSchool ? <Videos /> : <Login />
    
  )
}
