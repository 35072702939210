import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";


const initialState = {
  isLoggedIn:
  Cookies.get("token")&&Cookies.get("role")===Cookies.get("current_role")? true : false,
};

const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    QRLogin: (state) => {
      if (Cookies.get("token")&&Cookies.get("role")===Cookies.get("current_role")) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    
  },
});

export const { QRLogin, logout  } =
  loginSlice.actions;
export default loginSlice.reducer;
