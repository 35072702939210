import axios from "axios";
import Swal from 'sweetalert2';
import Cookies from "js-cookie";
export const endPoint1 = "https://studiorinternational.in/apis"
const instance = axios.create({
  baseURL:endPoint1
});
instance.defaults.headers.common["Accept"] = "application/json";



instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(error.response.data.message);
    // If the error status is 401, redirect the user to the login page
    if (error.response.status === 401) {
      Cookies.remove("token")
      Cookies.remove('profile')
      Cookies.remove('user')
      Cookies.remove('login')
      Cookies.remove('role')
      return Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    }
    if (error.response.status === 403) {
      return Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
      
    }
    if (error.response.status === 404) {
      return Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    }
    if(error.response.status === 422){
      return Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    }
    if (error.response.status === 500) {
      return Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    }
   
    // Return the error
  }
);

export default instance;
