import instance from "./instace"

export const loginUser = async(email,password)=>{
    try{
        const get = await instance.post('/api/qruser/login',{
          email,
          password
        })
        return get.data;
    }catch(e){
        return false;
    }
}
