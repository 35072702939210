import React, { Suspense, lazy, useEffect, useState } from 'react'
import "./App.css";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { QRPaths } from './service/qrService';
import { useSelector } from 'react-redux';
import Main from './pages/Main';
import NoFound from './pages/NoFound';
import paths from './assets/qrcode.json'
function App() {
    const isSchool =  useSelector((state) => state.loginSlice.isLoggedIn);
    const [qrRoutes, setQrRoutes] = useState([]);
    const Login = lazy(()=> import("./pages/Login"));
    const fetch = async()=>{
     let get = paths;
      setQrRoutes(get.message)
      localStorage.removeItem('qrPaths')
        localStorage.setItem('qrPaths',JSON.stringify(get.message))
        console.log(get.message)
    }
    useEffect(()=>{
      fetch();
    },[])

  return (
    <BrowserRouter>
      <Suspense>
      <Routes>
        <Route path="/" element={<Login/>} />
          {
            qrRoutes.length > 0 ? 
              qrRoutes.map((e,i)=>(
                <Route key={i} path={e.route} element={<Main/>} />
              ))
            :null
          }
           <Route path="*" element={<NoFound/>} />
      </Routes>
     
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
